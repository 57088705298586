import { React } from 'react'
import { Button, Drawer, List } from '@mui/material';
import ConnectorsFilterSection from './ConnectorsFilterSection';
import KilowattSpeedFilterSection from './KilowattSpeedFilterSection';
import PriceFilterSection from './PriceFilterSection';

export default function FiltersDrawer({ showFilters, 
    onCloseFilters,  
    connectorTypes, 
    filteredConnectorTypeId, 
    onClearFilterClicked, 
    onPriceFilterSelected, 
    onConnectorFilterSelected,
    onKilowattSpeedFilterSelected,
    filteredPrice,
    filteredKilowattSpeed}) {
        
    const onPriceFilterSectionUpdated = (price) => {
      onPriceFilterSelected(price)
    }

    const onConnectorSectionUpdated = (connectorId) => {
      onConnectorFilterSelected(connectorId)
    }

    const onKilowattSpeedSectionUpdated = (kilowattSpeed) => {
      onKilowattSpeedFilterSelected(kilowattSpeed)
    }

   const clearFilters = () => {
      onClearFilterClicked()
    }
      
    return (
      <Drawer
        anchor='right'
        open={showFilters}
        onClose={onCloseFilters}
        onKeyDown={onCloseFilters}>
        <List sx= {{ margin: 1}}>
            <PriceFilterSection
              priceType={filteredPrice}
              onPriceFilterSelected={onPriceFilterSectionUpdated}
            />  
            <ConnectorsFilterSection 
             connectorTypes={connectorTypes}
             filteredConnectorTypeId={filteredConnectorTypeId}
             onConnectorFilterSelected={onConnectorSectionUpdated}/>
             <KilowattSpeedFilterSection
                filteredKilowattSpeed={filteredKilowattSpeed}
                onKilowattSpeedFilterSelected={onKilowattSpeedSectionUpdated}
             />
        </List>
        <Button sx={{margin: 2}} onClick= { e =>
                    {
                        e.stopPropagation()
                        clearFilters()
                        onCloseFilters()
                    }
                } variant="contained">Clear
            </Button>
      </Drawer>
    )
}