import { React } from 'react'
import { Box, Card, Typography, RadioGroup, FormControlLabel, Radio, Link } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from '../../theme'
import { Stack, Skeleton } from '@mui/material';

export default function ConnectorsFilterSection({ filteredConnectorTypeId, connectorTypes, onConnectorFilterSelected }) {

    if (connectorTypes.length == 0) {
        return <Stack sx={{ m: 2}} spacing={1}>
        <Skeleton variant="rounded" width={210} height={20} />
        <Skeleton variant="rounded" width={210} height={20} />
        <Skeleton variant="rounded" width={210} height={20} />
        <Skeleton variant="rounded" width={210} height={20} />
        <Skeleton variant="rounded" width={210} height={20} />
        <Skeleton variant="rounded" width={210} height={20} />
        </Stack>
    } else {
        return (
            <ThemeProvider theme={theme}>
             <Card sx={{ m: 2 }}>
              <Box sx= {{ m: 2}}>
              <Stack direction="row" spacing={2}>
              <Typography variant="h6"> 🔌 Connector Type <Link target="_blank" rel="noreferrer" href="https://www.rac.co.uk/drive/electric-cars/charging/charger-types-connectors-speed/">{"?"} </Link></Typography>
              </Stack>
                <RadioGroup column
                value={filteredConnectorTypeId}
                onChange={ (event) => onConnectorFilterSelected(event.target.value)}> {
                    Object.keys(connectorTypes).map((key) => 
                        <FormControlLabel 
                            key={connectorTypes[key].ConnectorTypeID} 
                            control={<Radio/>} 
                            value={connectorTypes[key].ConnectorType}
                            label={connectorTypes[key].ConnectorType} />
                    )
                }
                </RadioGroup>
                </Box>
            </Card>
            </ThemeProvider>
        );
    }
}