import React from 'react'
import Grid from '@mui/material/Unstable_Grid2';
import ConnectorsFilterSection from './ConnectorsFilterSection';
import KilowattSpeedFilterSection from './KilowattSpeedFilterSection';
import PriceFilterSection from './PriceFilterSection';
import { Button } from '@mui/material';

export default function Filters({ showFilters,
    onCloseFilters,  
    connectorTypes, 
    filteredConnectorTypeId, 
    onClearFilterClicked, 
    onPriceFilterSelected, 
    onConnectorFilterSelected,
    onKilowattSpeedFilterSelected,
    filteredPrice,
    filteredKilowattSpeed }) {

        const onPriceFilterSectionUpdated = (price) => {
            onPriceFilterSelected(price)
          }
      
          const onConnectorSectionUpdated = (connectorId) => {
            onConnectorFilterSelected(connectorId)
          }
      
          const onKilowattSpeedSectionUpdated = (kilowattSpeed) => {
            onKilowattSpeedFilterSelected(kilowattSpeed)
          }
      
         const clearFilters = () => {
            onClearFilterClicked()
          }
      
        return(
            <React.Fragment>
                <Grid container rowSpacing={1} columnSpacing={1}>
                    <Grid xs={12}>
                    <PriceFilterSection
                        priceType={filteredPrice}
                        onPriceFilterSelected={onPriceFilterSectionUpdated}
                    />  
                    </Grid>
                    <Grid xs={12}>
                    <ConnectorsFilterSection 
                        connectorTypes={connectorTypes}
                        filteredConnectorTypeId={filteredConnectorTypeId}
                        onConnectorFilterSelected={onConnectorSectionUpdated}/>
                    </Grid>
                    <Grid xs={12}>
                    <KilowattSpeedFilterSection
                         filteredKilowattSpeed={filteredKilowattSpeed}
                         onKilowattSpeedFilterSelected={onKilowattSpeedSectionUpdated}/>
                    </Grid>
                    <Button sx={{margin: 2, width: '100%'}} onClick= { e =>
                    {
                        e.stopPropagation()
                        clearFilters()
                        onCloseFilters()
                    }
                } variant="contained">Clear
                  </Button>
                </Grid>
            </React.Fragment>
        )
}