import React, { PureComponent } from 'react'
import { MapView } from '../components/MapView.js'
import NavBar from '../components/NavBar.js'
import ElectricChargerDetail from '../components/ElectricChargerDetail.js'
import { Stack } from '@mui/material'
import { AboutDialog } from '../components/AboutDialog.js'
import { ComingSoonDialog } from '../components/ComingSoonDialog.js'
import ElectricChargerDetailDrawer from '../components/ElectricChargerDetailDrawer'
import MediaQuery from 'react-responsive'
import FiltersDrawer from '../components/filters/FiltersDrawer.js'
import Filters from '../components/filters/Filters.js'

const baseUrl = process.env.REACT_APP_SERVER_BASE_URL

// Show Apple Maps using the standard view (t=m), 
// Using car as the mode of transport (dirflg=d) 
// And appending a destination address (daddr=)
const appleMapsUrl = "http://maps.apple.com/?t=m&dirflg=d&daddr="

const googleMapsUrl = "https://www.google.com/maps/dir/?api=1&destination="

export default class App extends PureComponent {

  constructor (props) {
    super(props)
    this.state = {
      postcode: '',
      electricPoints: [],
      connectorTypes: [],
      connectorTypeId: '',
      flyToOccurred: false,
      selectedElectricCharger: null,
      showAboutModal: false,
      showComingSoonModal: false,
      showFilters: false,
      priceType: '',
      kilowattSpeed: '',
      filterAnchor: null,
    }

    this.onSearchButtonTapped = this.onSearchButtonTapped.bind(this)
    this.onMapMoved = this.onMapMoved.bind(this)
    this.onElectricChargerClicked = this.onElectricChargerClicked.bind(this)
    this.onCloseChargerInfo = this.onCloseChargerInfo.bind(this)
    this.onMapsDirectionsClicked = this.onMapsDirectionsClicked.bind(this)
    this.onAboutTapped = this.onAboutTapped.bind(this)
    this.onCloseAboutDialog = this.onCloseAboutDialog.bind(this)
    this.onCountrySelected = this.onCountrySelected.bind(this)
    this.onCloseComingSoonDialog = this.onCloseComingSoonDialog.bind(this)
    this.onDrawerOpened = this.onDrawerOpened.bind(this)
    this.onFilterTapped = this.onFilterTapped.bind(this)
    this.onCloseFilters = this.onCloseFilters.bind(this)
    this.onClearFilterClicked = this.onClearFilterClicked.bind(this)
    this.onPriceFilterSelected = this.onPriceFilterSelected.bind(this)
    this.onConnectorFilterSelected = this.onConnectorFilterSelected.bind(this)
    this.onKilowattSpeedFilterSelected = this.onKilowattSpeedFilterSelected.bind(this)
  }

  componentDidMount() {
    this.getConnectorTypes()
      .then(response => response.json())
      .then(connectorTypes => this.setState({ connectorTypes: connectorTypes.ConnectorType}))
      .catch(error => console.log(error))
  }

  async getElectricPointsForPostcode (postcode) {
    var url = new URL(baseUrl + `/electricPoints?postcode=${postcode}`)
    const connectorTypeId = this.state.connectorTypeId
    if (connectorTypeId != null) {
      url.searchParams.append('connector-type-id', connectorTypeId)
    }
    return await fetch(url)
  }

  async getConnectorTypes() {
    const url = new URL(baseUrl + '/electricConnectorTypes')
    return await fetch(url)
  }

  onSearchButtonTapped (postcode) {
    this.setState({
      postcode: postcode,
      electricPoints: [],
      flyToOccurred: false,
      loading: true,
      drawerOpen: false,
    })

    this.getElectricPointsForPostcode(postcode)
      .then(response => response.json())
      .then(electricPoints => this.setState({ electricPoints: electricPoints.ChargeDevice, loading: false }))
      .catch(error => console.log(error))
  }

  onFilterTapped (anchor) {
    this.setState({
      showFilters: true,
      filterAnchor: anchor
    })
  }

  onCloseFilters () {
    this.setState({
      showFilters: false,
      filterAnchor: null
    })
  }

  onAboutTapped () {
    this.setState({
      showAboutModal: true 
    })
  }

  onCloseAboutDialog () {
    this.setState({
      showAboutModal: false
    })
  }

  onCountrySelected() {
    this.setState({
      showComingSoonModal: true 
    })
  }

  onCloseComingSoonDialog () {
    this.setState({
      showComingSoonModal: false
    })
  }

  onElectricChargerClicked (electricCharger) {
    this.setState({
      selectedElectricCharger: electricCharger
    })
  }

  onMapMoved () {
    this.setState({
      flyToOccurred: true
    })
  }

  onCloseChargerInfo () {
    this.setState({
      selectedElectricCharger: null
    })
  }

  onDrawerOpened () {
    this.setState({
      drawerOpen: true
    })
  }

  onMapsDirectionsClicked (postcode, platform) {

    var url = ""

    if (platform == "apple") {
      url = appleMapsUrl + postcode
    } else {
      url = googleMapsUrl + postcode
    }

    window.open(url, "_blank")
  }

  onPriceFilterSelected(price) {
    this.setState({
      priceType: price
    })
  }

  onConnectorFilterSelected = (connectorTypeId) => {
    this.setState({
      connectorTypeId: connectorTypeId
    })
  }

  onKilowattSpeedFilterSelected = (kilowattSpeed) => {
    this.setState({
      kilowattSpeed: kilowattSpeed
    })
  }

  onClearFilterClicked = () => {
    this.setState({
      connectorTypeId: '',
      priceType: '',
      kilowattSpeed: ''
    })
  }

  render () {
    const loading = this.state.loading
    const electricPoints = this.state.electricPoints
    const flyToOccurred = this.state.flyToOccurred
    const showAboutModal = this.state.showAboutModal
    const showComingSoonModal = this.state.showComingSoonModal
    const showFilters = this.state.showFilters
    const selectedElectricCharger = this.state.selectedElectricCharger
    const filteredConnectorTypeId = this.state.connectorTypeId
    const filteredPrice = this.state.priceType
    const filteredChargingSpeed = this.state.kilowattSpeed

    return (
        <Stack sx= {{ height: "100%" }}>
          <NavBar
            loading={loading}
            onSearchButtonTapped={this.onSearchButtonTapped}
            onFilterTapped={this.onFilterTapped}
            onAboutTapped={this.onAboutTapped}
            onCountrySelected={this.onCountrySelected}/>
            <MapView
                electricPoints={electricPoints} 
                flyToOccurred={flyToOccurred}
                chargerSelected={selectedElectricCharger != null}
                onMapMoved={this.onMapMoved}
                onElectricChargerClicked={this.onElectricChargerClicked}
                filteredPrice={filteredPrice}
                filteredConnectorType={filteredConnectorTypeId}
                filteredChargingSpeed={filteredChargingSpeed}/>

            { showAboutModal &&
              <AboutDialog closeDialog={this.onCloseAboutDialog}/>
            }

            { showComingSoonModal &&
              <ComingSoonDialog closeDialog={this.onCloseComingSoonDialog}/>
            }

            { selectedElectricCharger &&
              <div id="electric-charger-detail">
              <MediaQuery minWidth={1224}>
              <ElectricChargerDetailDrawer 
                  electricCharger={selectedElectricCharger}
                  onDrawerClosed={this.onCloseChargerInfo}
                  onMapsDirectionsClicked={this.onMapsDirectionsClicked}/>
              </MediaQuery>
              <MediaQuery maxWidth={1224}>
              <ElectricChargerDetail
                  electricCharger={selectedElectricCharger}
                  onMapsDirectionsClicked={this.onMapsDirectionsClicked}
                  onCloseChargerInfo={this.onCloseChargerInfo}/>
              </MediaQuery>
              </div>
            }

            { showFilters && <div id="filters">
            <MediaQuery minWidth={1224}>
            <FiltersDrawer 
              showFilters={this.state.showFilters}
              onCloseFilters={this.onCloseFilters}
              connectorTypes={this.state.connectorTypes}
              filteredConnectorTypeId={this.state.connectorTypeId}
              onConnectorFilterSelected={this.onConnectorFilterSelected}
              filteredPrice={this.state.priceType}
              onPriceFilterSelected={this.onPriceFilterSelected}
              filteredKilowattSpeed={this.state.kilowattSpeed}
              onKilowattSpeedFilterSelected={this.onKilowattSpeedFilterSelected}
              onClearFilterClicked={this.onClearFilterClicked}/>
            </MediaQuery>
            <MediaQuery maxWidth={1224}>
            <Filters
              showFilters={this.state.showFilters}
              onCloseFilters={this.onCloseFilters}
              connectorTypes={this.state.connectorTypes}
              filteredConnectorTypeId={this.state.connectorTypeId}
              onConnectorFilterSelected={this.onConnectorFilterSelected}
              filteredPrice={this.state.priceType}
              onPriceFilterSelected={this.onPriceFilterSelected}
              filteredKilowattSpeed={this.state.kilowattSpeed}
              onKilowattSpeedFilterSelected={this.onKilowattSpeedFilterSelected}
              onClearFilterClicked={this.onClearFilterClicked}/>
            </MediaQuery>
            </div>
            }
        </Stack>
    )
  }
}