import { React } from 'react'
import { Box, Card, Typography, RadioGroup, FormControlLabel, Radio, Link } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from '../../theme'

export default function PriceFilterSection({ priceType, onPriceFilterSelected }) {

    const price = ["Free", "Paid"]
    
    return (
        <ThemeProvider theme={theme}>
        <Card sx={{ m: 2 }}>
         <Box sx= {{ m: 2}}>
         <Typography variant="h6"> 💷 Price <Link target="_blank" rel="noreferrer" href="https://energysavingtrust.org.uk/advice/charging-electric-vehicles#jumpto-16">{"?"} </Link></Typography>
           <RadioGroup column
           value={priceType}
           onChange={ (event) => onPriceFilterSelected(event.target.value)}> {
               Object.keys(price).map((key) => 
                   <FormControlLabel 
                       key={price[key]} 
                       control={<Radio/>} 
                       value={price[key]}
                       label={price[key]} />
               )
           }
           </RadioGroup>
           </Box>
       </Card>
       </ThemeProvider>
    );
}